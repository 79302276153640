import React from "react";
import { Button, ButtonKind, ButtonSize } from "../default";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/scalis-components/core/dropdown-menu";
import { ActionButtonProps } from "./action-button.types";
import { cn } from "~/utils/cn";

export const ActionButton: React.FC<ActionButtonProps> = ({
  actionsItems,
  bordered = true,
}) => {
  return (
    <DropdownMenu modal={false}>
      {bordered ? (
        <DropdownMenuTrigger asChild>
          <Button size={ButtonSize.xs} kind={ButtonKind.secondary}>
            <i className="fa-solid fa-ellipsis p-1 text-icon-neutral-20" />
          </Button>
        </DropdownMenuTrigger>
      ) : (
        <DropdownMenuTrigger className="size-8">
          <i className="fa-solid fa-ellipsis p-1 text-icon-neutral-20" />
        </DropdownMenuTrigger>
      )}

      <DropdownMenuContent
        align="end"
        className="min-w-[192px] rounded-xl bg-white p-2"
      >
        {actionsItems.map(item => {
          return (
            <DropdownMenuItem
              key={item.label}
              className="p-2 hover:cursor-pointer hover:bg-background-hover"
              onClick={() => item.onClick()}
            >
              <div className="flex items-center gap-2">
                <div className="flex h-4 w-4 items-center justify-center">
                  <i className={cn(item.icon, "text-neutral-primary")} />
                </div>
                <span className="text-sm font-normal text-neutral-primary">
                  {item.label}
                </span>
              </div>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
