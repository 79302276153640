import { IconProps } from "../../icon";

export enum InputVariants {
  disabled = "disabled",
  error = "error",
  small = "small",
  medium = "medium",
  startAdornment = "startAdornment",
  endAdornment = "endAdornment",
}

export enum InputAdornmentKind {
  normal = "normal",
  button =  "button",
};

export type InputAdornment = {
  icon: string;
  kind?: InputAdornmentKind;
  disabled?: boolean;
  onClick?: () => void;
  'aria-label'?: string;
} & IconProps;

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  helpMessage?: string;
  wordCount?: number;
  startAdornment?: InputAdornment;
  endAdornment?: InputAdornment;
  small?: boolean;
  medium?: boolean;
  setValue?: (name: string, value: string) => void;
  tooltip?: string;
  disableNegative?: boolean;
  inputClassName?: string;
}
