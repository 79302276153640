import { tv } from "tailwind-variants";
import { cn } from "~/utils/cn";
import { ButtonSize, ButtonWidth } from "../button.types";

export const baseButton = tv({
  base: cn(
    "inline-flex justify-center items-center gap-2 rounded-xl select-none",
    "text-center text-sm font-medium leading-6",
  ),
  variants: {
    width: {
      [ButtonWidth.auto]: "w-auto",
      [ButtonWidth.half]: "w-1/2",
      [ButtonWidth.full]: "w-full text-center",
    },
    size: {
      [ButtonSize.medium]: "py-2 px-4 h-10",
      [ButtonSize.small]: "py-1 px-4 h-8",
      [ButtonSize.xs]: "py-1 px-1 h-8",
    },
    loading: {
      true: "disabled:bg-button-disabled disabled:text-neutral-disabled",
    },
  },
  defaultVariants: {
    size: ButtonSize.medium,
    width: ButtonWidth.auto,
  },
});
