"use client";

import { Button, ButtonKind, ButtonWidth } from "../../button";
import { FileItem } from "./components/file-item";
import { FileInputProps, UploadedFile } from "./file-input.types";
import { useState } from "react";

export const FileInput = ({
  allowedExtension,
  name,
  isMultiple,
  label,
  onFileChange,
  onFileRemoval,
  error: formError,
  children,
}: FileInputProps) => {
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [error, setError] = useState<string>("");

  const handleFileChange = async (newFiles: FileList) => {
    const formattedFiles = Array.from(newFiles);

    try {
      await onFileChange?.(formattedFiles);
      isMultiple
        ? setFiles(prev => [...prev, ...formattedFiles])
        : setFiles(formattedFiles);
    } catch (e) {
      setError("Please try again, something wrong happened!");
    }
  };

  const handleRemove = (index: number) => {
    const filteredFiles = files.filter((_, i) => i !== index);
    setFiles(filteredFiles);
    onFileRemoval?.();
  };

  const errorMessage = formError || error;

  return (
    <div className="flex flex-col">
      <div>
        <input
          type="file"
          name={name}
          multiple={isMultiple}
          className="hidden"
          id="fileInput"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleFileChange(event.target.files!)
          }
        />

        {!files?.length &&
          (children ?? (
            <Button
              kind={ButtonKind.tertiary}
              className="relative w-full"
              width={ButtonWidth.full}
              type="button"
              asChild
            >
              <label
                className="flex w-full cursor-pointer items-baseline justify-center gap-2"
                htmlFor="fileInput"
              >
                {label}
                <div className="size-4">
                  <i className="fa-regular fa-upload" />
                </div>
              </label>
            </Button>
          ))}
      </div>
      <div>
        {files?.map?.(({ name }, index) => (
          <div key={name}>
            <FileItem
              name={name}
              fileKey={`${name}-${index}`}
              index={index}
              handleRemove={handleRemove}
            />
          </div>
        ))}
      </div>
      {errorMessage && (
        <div
          className="mt-6 text-left text-xs text-error"
          hidden={!errorMessage}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};
