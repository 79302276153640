import { Dispatch, SetStateAction } from "react";

export enum InputEditorVatriants {
  disabled = "disabled",
  error = "error",
}

export interface InputEditorProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  setValue?: (name: string, value: string) => void;
}

export interface EditorProps {
  initialContent?: string;
  setValue?: (name: string, value: string) => void;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  contentClassName?: string;
  placeholderClassName?: string;
  hidePlaceHolderOnFocus?: boolean;
  toolbarDivider?: boolean;
  value?: string;
  error?: string;
  name?: string;
}



