import React from "react";
import { cn } from "~/utils/cn";
import { IconProps } from "./icon.types";

export const Icon: React.FC<IconProps> = ({
  icon,
  size,
  className,
}) => {
  return <i aria-hidden className={cn(icon, className, size ? `text-[${size}px]` : '')} />
};
