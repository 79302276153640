"use client";

import { forwardRef } from "react";
import { $getRoot, LexicalEditor } from "lexical";
import { $generateHtmlFromNodes } from "@lexical/html";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import ExampleTheme from "./config";
import { ToolbarPlugin } from "../toolbar";
import { useRef, useState } from "react";
import { cn } from "~/utils/cn";
import { inputEditorClassName } from "./editor.styles";
import { EditorProps } from "./editor.types";
import { ControlDisabled } from "../control-disabled";
import { ClearContent } from "../clear-content";
import { LoadInitialContent } from "../load-initial-content";

const editorConfig = {
  namespace: "Scalis",
  theme: ExampleTheme,
  onError(error: unknown) {
    throw error;
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

export const Editor = forwardRef<LexicalEditor | undefined, EditorProps>(({
  initialContent,
  setValue,
  className,
  disabled = false,
  placeholder,
  autoFocus = false,
  contentClassName,
  placeholderClassName,
  hidePlaceHolderOnFocus = false,
  toolbarDivider = false,
  value,
  error,
  name,
}, ref) => {
  const usedValueProps = !!value;
  const isMounted = useRef(false);
  const [focused, setFocused] = useState(false);

  const onChange = (editor: LexicalEditor) => {
    editor.update(() => {
      const root = $getRoot();

      if (!isMounted.current && usedValueProps) {
        root.clear();
        isMounted.current = true;
      }

      const firstChild = root.getFirstChild();
      if (!firstChild && usedValueProps) {
        if (setValue) {
          setValue("", "");
        }
        return;
      }

      const htmlString = $generateHtmlFromNodes(editor, null);

      if (setValue) {
        setValue(name ?? "", htmlString);
      }
    });
  };

  const { containerStyles, headerStyles, placeholderStyles, richTextStyles } =
    inputEditorClassName({
      disabled,
      error: !!error,
    });

  return (
    <LexicalComposer initialConfig={{ ...editorConfig, editable: !disabled }}>
      <div className={containerStyles({ class: className })}>
        <div className={headerStyles()}>
          <ToolbarPlugin disabled={disabled} />
        </div>
        <div className="relative">
          <RichTextPlugin
            placeholder={
              <div
                className={cn(
                  placeholderStyles({ class: placeholderClassName }),
                  { invisible: (hidePlaceHolderOnFocus && focused) || value },
                )}
              >
                {placeholder}
              </div>
            }
            contentEditable={
              <ContentEditable
                className={richTextStyles({ class: contentClassName })}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <LoadInitialContent initialContent={initialContent} />
          <HistoryPlugin />
          {autoFocus && <AutoFocusPlugin />}
          <ListPlugin />
          <LinkPlugin />

          <OnChangePlugin
            onChange={(editorState, editor) => onChange(editor)}
          />
          {usedValueProps && <ClearContent value={value} />}
          <ControlDisabled disabled={disabled} />
        </div>
      </div>
    </LexicalComposer>
  );
});
